<template>
  <div ref="preview" class="media-instance-preview">
  </div>
</template>

<script>
import {Preview} from '@creatomate/preview';
import {PromoTemplates} from "@/db/PromoTemplates";
import {renderMixins} from "app-to-up-vue-renderer/src/renderer/components/renderMixins";

export default {
  name: "MediaInstancePreview",
  mixins: [renderMixins],
  props: {
    mediaInstance: {}
  },
  mounted() {

    // Get width and height from dom
    this.width = this.$refs.preview.clientWidth;
    this.height = this.$refs.preview.clientHeight;

    //this.$refs.preview.innerHTML = this.code
    this.$watch("mediaInstance", this.loadInstance, {
      immediate: true,
      deep: true
    });
  },

  data: () => ({
    player: null,
    template: false,
    width: 0,
    height: 0
  }),

  /**
   * Destroy player
   */
  beforeUnmount() {
    console.log('destroy')
    if(this.player) {
      this.player.dispose();
    }
  },

  methods: {

    /**
     * Update values
     * @return {Promise<void>}
     */
    async updateValues() {
      // Set modifications
      if(this.mediaInstance?.assets) {
        const vals = {};

        // Remove empty values
        for(const [key, value] of Object.entries(this.mediaInstance?.assets || {})) {
          if(!value?.value) continue;

          // Store value
          vals[key] = value.type === 'text' ? value?.value : this.renderer.a2u.assetPath(value?.value);
        }

        // Set modifications
        await this.player?.setModifications(vals);
      }
    },

    /**
     * Start player
     * @return {Promise<void>}
     */
    async loadInstance() {

      // check if code exists
      if(this.mediaInstance?.promo_template_id && this.mediaInstance?.promo_template_id !== this.template?.id) {

        // Load promo template
        this.template = await PromoTemplates.find(this.mediaInstance?.promo_template_id)

        // Exit if no template
        if (!this.template) {
          throw new Error('Template not found')
        }

        // Create player
        this.player = new Preview(this.$refs.preview, 'player', process.env.VUE_APP_CREATOMATE_API_KEY);

        // Set source
        this.player.onReady = async () => {
          // Parse template code
          const templateCode = JSON.parse(this.template.template_code);

          // Set width and height
          if(this.width && this.height) {
            templateCode.width = this.width;
            templateCode.height = this.height;
          }

          await this.player.setSource(templateCode);
          await this.updateValues();
        }
      } else {
        // Update values
        await this.updateValues();
      }
    }
  },
}

</script>

<style lang="scss">

.media-instance-preview {
  width: 100%;
  height: 100%;
  border:3px solid #333;
}

</style>
