<template>
  <div v-if="editorUrl" class="column col full-width">
    <iframe frameborder="0" ref="editor" class="col" :src="editorUrl"></iframe>
  </div>
</template>

<script>

import {ProjectMedia} from "@/db/ProjectMedia";
import axios from "axios";

export default {
  name: "MediaDesigner",
  inject: ["renderer"],
  props: {
    mediaResource: {},
  },

  data: () => ({
    isReady: false
  }),

  /**
   * Created hook
   */
  async created() {

    // If no token - create one
    if (!this.mediaResource?.media_token) {
      await this.getToken();
    }

    // Close if no token
    if (!this.mediaResource?.media_token) {
      this.$emit('error');
      return;
    }

    // Add event listener for messages from the iframe
    window.addEventListener('message', this.onMessage);

    // All ok
    this.isReady = true;
  },

  /**
   * Before unmount
   */
  beforeUnmount() {
    window.removeEventListener('message', this.onMessage);
  },

  methods: {

    /**
     * Send message to the editor
     * @param type
     * @param data
     */
    sendToEditor(type, data) {
      // Send data to iframe
      this.$refs.editor.contentWindow.postMessage({type, data}, '*');
    },

    /**
     * Get project assets and apply to the editor
     * @return {Promise<void>}
     */
    async applyAssets() {

      // Load all media resources
      const projectMedias = await ProjectMedia.query().where({
        project_id: this.mediaResource.project_id
      }).get();

      // Rearrange array as object with group name (owner_type-type) and list of media
      const assets = {}
      projectMedias.map((media) => {
        const key = media.owner_type;
        assets[key] = assets[key] || [];
        assets[key].push({
          url: this.renderer.a2u.assetPath(media.media_file),
          title: media.description,
          type: media.type,
        });
      });

      // Set assets
      this.sendToEditor('setMediaAssets', assets);
    },

    /**
     * On message
     * @param event
     */
    async onMessage(event) {
      // Check for action
      if(event.data?.data?.action === 'init') {
          return this.applyAssets();
      }

      // Check for action
      if(event.data?.data?.action === 'saved') {

        try {
          // Show loading
          this.$q.loading.show();

          // Download the image and save  event.data?.data?.image_url
          const blob = (await axios.get(event.data?.data?.image_url, {responseType: 'blob'}))?.data
          const file = new File([blob], 'image.png', {type: 'image/png'});
          const res = await this.app.client.upload("image.png", file, this.renderer.a2u.getBackendUrl());

          // Store to mediaResource
          await ProjectMedia.remote().save({
            id: this.mediaResource.id,
            media_file: {
              type: 'file',
              source_url: 'storage:' + res,
              storage_module_id: this.renderer.a2u.getBackendId(),
            }
          })

          // Emit save event
          this.$emit('save', res);
        } catch (e) {
          console.error(e);
          this.$q.notify({
            color: "negative",
            message: e.message
          })
        } finally {
          this.$q.loading.hide();
        }
      }
    },

    /**
     * Get new media token
     */
    async getToken() {
      try {

        // Show loading
        this.$q.loading.show();

        // eslint-disable-next-line vue/no-mutating-props
        this.mediaResource.media_token = await this.app.client.call(this.renderer.a2u.getBackendUrl() + "/media", "createToken", this.mediaResource.size, this.mediaResource.media_file ? this.renderer.a2u.assetPath(this.mediaResource.media_file) : undefined);

        // Save the mediaResource
        await ProjectMedia.remote().save({
          id: this.mediaResource.id,
          media_token: this.mediaResource.media_token
        })
      } catch (e) {
        console.error(e);
        this.$q.notify({
          color: "negative",
          message: e.message
        })
      } finally {
        this.$q.loading.hide();
      }
    }
  },

  computed: {

    /**
     * Get the editor URL
     * @return {string}
     */
    editorUrl() {
      return this.mediaResource?.media_token ? process.env.VUE_APP_AME_EDITOR_URL + "/" + this.mediaResource.media_token : false;
    }
  }
}

</script>
