import MediaInstancePreview from "@/components/MediaInstance/MediaInstancePreview.vue";
import MediaInstanceSettings from "@/components/MediaInstance/MediaInstanceSettings.vue";
import JSONPreview from "@/components/JSONPreview/JSONPreview.vue";
import TemplateSettings from "@/components/PromoTemplate/TemplateSettings.vue";
import TemplatePreview from "@/components/PromoTemplate/TemplatePreview.vue";
import VideoToAsset from "@/components/VideoToAsset/VideoToAsset.vue";
import MediaDesigner from "@/components/MediaDesigner.vue";

export const componentList = {
    //"test": TestComponent
    MediaInstancePreview,
    MediaInstanceSettings,
    JSONPreview,
    TemplatePreview,
    TemplateSettings,
    VideoToAsset,
    MediaDesigner
}
