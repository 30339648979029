<template>
  <div class="template-settings row q-gutter-x-md"  v-if="template?.id && width && height">

    <div class="column">
      <div class="preview bg-grey-11">
        <template-preview :code="template.template_code" :width="width" :height="height" :values="currentAssets"/>
      </div>

      <div class="row q-gutter-x-sm bg-grey-4 q-pa-sm">
      <q-btn flat class="bg-blue-1 col" icon="edit" label="Update template" @click="$emit('update-code')"/>
      <q-btn flat class="bg-blue-1" icon="draw" label="Preview" @click="generatePreview"/>
      </div>
    </div>

    <div class="assets col q-gutter-y-sm">
      <div :class="`prompt-${val?.type}`" v-for="([name, val], k) of Object.entries(templateVars || [])" :key="k" class="row q-gutter-x-md">
        <q-input class="col" v-model="mutate(template).prompts[name].value" :label="val.type + ': ' + name" :placeholder="val.text" :hint="val.text ? `Example: ` + val.text : undefined"/>
        <q-input v-if="val.type==='text'" v-model="mutate(template).prompts[name].length" label="Max length"/>
      </div>
    </div>

  </div>
</template>

<script>

import TemplatePreview from "@/components/PromoTemplate/TemplatePreview.vue";
import {renderMixins} from "app-to-up-vue-renderer/src/renderer/components/renderMixins";
import {PromoTemplates} from "@/db/PromoTemplates";

export default {
  name: "TemplateSettings",
  mixins: [renderMixins],
  components: {TemplatePreview},
  emits: ['update-code'],
  data: () => ({
    width: 0,
    height: 0,
    currentAssets: false
  }),
  created() {

    // Watch template
    this.$watch("template", () => {
      if(this.template) {
        this.mutate(this.template).prompts = this.template.prompts || {}
        this.updateSize();
      }
    }, {deep: true, immediate: true});

    // Watch template vars
    this.$watch("templateVars", (vars) => {

      // Get current prompts
      const prompts = this.template?.prompts || {}

      // Init template prompts
      for(let vr in vars) {
        if(!prompts[vr] || typeof prompts[vr] !== 'object') prompts[vr] = {
          value: "",
          type: vars[vr].type,
          length: 0
        }
      }

      // Remove vars that are not in the template
      for(let pr in prompts) {
        if(!vars[pr]) delete prompts[pr]
      }

      // Store prompts
      if(this.template) this.mutate(this.template).prompts = prompts;
    }, {deep: true, immediate: true});
  },
  props: {
    template: {},
    staticWidth: {
      type: Number,
      default: 400
    }
  },

  computed: {

    /**
     * Get template vars
     * @return {{}}
     */
    templateVars() {

      // Texts to translate
      const textsToReplace = {};

      // Parse template source
      const templateSource = JSON.parse(this.template?.template_code || "{}")

      // Go through all elements
      const getAllVars = (node) => {
        // Extract text assets from source
        for (const e of node?.elements || []) {
          if (e.dynamic) textsToReplace[e.name || e.id] = {
            text: e.text,
            type: e.type
          }
          getAllVars(e)
        }
      }

      // Process source
      getAllVars(templateSource);

      // Return texts
      return textsToReplace
    }
  },

  methods: {

    /**
     * Generate preview
     */
    async generatePreview() {

      try {
        // Laoding
        this.$q.loading.show();

        // Save prompts
        await PromoTemplates.remote().save({
          id: this.template.id,
          prompts: this.template.prompts
        })

        // Generate and return assets
        const assets = await this.renderer.a2u.callFunction("AIComposer", "generatePromoAssets", {
          templateId: this.template.id
        });

        // Set assets
        this.currentAssets = assets?.references?.promoAssets || {}
      } catch (e) {
        console.error(e)
        // Show error
        this.$q.notify({
          color: 'negative',
          message: e.message
        })
      }
      finally {
        // Hide loading
        this.$q.loading.hide();
      }
    },

    /**
     * Update size
     */
    updateSize() {

      // Get template params by type
      (this.parentDiagram.getStorage("constant").get("guides.mediaSizes") || []).map(el => {

        // Check type
        if(el.id === this.template?.subtype) {
          this.width = this.staticWidth;
          this.height = this.staticWidth / eval(el.ratio);
        }
      });
    }
  }
}

</script>

<style lang="scss">

.template-settings {
  .assets {
    .prompt-image {
      background: #f5f5f5;
      padding: 5px;
    }
  }
}

</style>
