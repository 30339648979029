<template>
  <q-scroll-area class="col">
    <div class="media-resource-selector q-gutter-sm q-pa-md">

      <div class="row q-gutter-x-md">
        <media-resource-list v-if="resourceType==='image'" media-type="image" :ownerId="solutionId" title="Solution images" ownerType="solution-image" @selected="$emit('selected', $event)"/>
      </div>

      <div class="row q-gutter-x-md">
        <media-resource-list v-if="resourceType==='image'" media-type="image" :ownerId="projectId" title="Logos" ownerType="project-logo" @selected="$emit('selected', $event)"/>
        <media-resource-list v-if="resourceType==='image'" media-type="image" :ownerId="projectId" title="Backgrounds" ownerType="project-background"  @selected="$emit('selected', $event)"/>
        <media-resource-list v-if="resourceType==='image'" media-type="image" :ownerId="projectId" title="Images" ownerType="project-image" @selected="$emit('selected', $event)"/>
      </div>

      <div class="row q-gutter-x-md">
        <template v-for="(f, k) of featureList" :key="k">
          <media-resource-list :mediaType="resourceType" :ownerId="f.id" :title="`${f.title} screenshots`" ownerType="project-feature" @selected="$emit('selected', $event)"/>
        </template>
      </div>

    </div>
  </q-scroll-area>
</template>

<script>

import MediaResourceList from "@/components/MediaSelector/MediaResourceList.vue";
import {ProjectFeatures} from "@/db/ProjectFeatures";

export default {
  name: "MediaResourceSelector",
  emits: ['selected'],
  components: {MediaResourceList},
  inject: ['renderer'],
  props: {
    projectId: {},
    solutionId: {},
    resourceType: {
      default: "image"
    }
  },
  methods: {
  },
  computed: {

    /**
     * Feature list
     * @return {*}
     */
    featureList() {
      return this.wait('featureList', ProjectFeatures.query().where({project_id: this.projectId,}).get(), [])
    }

    /**
     * Media resources
     * @return {*}
     */
    /*mediaResources() {
      return this.wait('mediaResources', async () => {

        // Resources
        const resources = {}

        // Load icons
        resources.logos = await ProjectMedia.query().where({
          owner_id: this.projectId,
          owner_type: 'project-logo'
        }).get()

        // Load backgrounds
        resources.backgrounds = await ProjectMedia.query().where({
          owner_id: this.projectId,
          owner_type: 'project-background'
        }).get()

        // Load screenshots
        resources.screenshots = await ProjectMedia.query().where({
          owner_id: this.projectId,
          owner_type: 'screenshot'
        }).get()

        // Load features
        resources.features = await ProjectFeatures.query().where({project_id: this.projectId,}).get()

        // Load each feature media
        for (const feature of resources.features) {
          feature.resources = await ProjectMedia.query().where({
            owner_id: feature.id,
            owner_type: 'project-feature'
          }).get()
        }

        // Return resources
        return resources;
      }, {})
    }*/
  },
}

</script>

<style lang="scss">
.media-resource-selector {

  margin-top:10px;

  .media-preview {
    border:1px solid #ddd;
  }

  .title {
    background: #eee;
    padding: 5px;
  }
}

</style>
