<template>
  <div v-if="mediaList?.length">
    <div class="title">{{title}}</div>
    <template v-for="(m, k) in mediaList" :key="k">
      <video v-if="mediaType==='video'" width="100" controls class="media-preview" @click="selectImage(m)">
        <source :src="getUrl(m)" type="video/mp4">
      </video>
      <q-img v-else width="100px" :ratio="getRatio(m)" class="media-preview" :src="getUrl(m)" fit="cover" @click="selectImage(m)"/>
    </template>
  </div>
</template>

<script>

import {ProjectMedia} from "@/db/ProjectMedia";

export default {
  name: "MediaResourceList",
  emits: ['selected'],
  props: ['ownerId', 'ownerType', 'mediaType', 'title'],
  inject: ['renderer'],

  // On created
  created() {
    // Subscribe to media
    ProjectMedia.remote().subscribe(71, {
      ownerId: this.ownerId,
      ownerType: this.ownerType
    });
  },

  // On before unmount
  beforeUnmount() {
    // Unsubscribe from media
    ProjectMedia.remote().unsubscribe("Get owner media", {
      ownerId: this.ownerId,
      ownerType: this.ownerType
    });
  },
  computed: {
    /**
     * Media list
     * @return {*}
     */
    mediaList() {
      return this.wait('mediaList', ProjectMedia.query().where({
        owner_id: this.ownerId,
        owner_type: this.ownerType,
        ...(this.mediaType ? {type: this.mediaType} : {})
      }).get(), []);
    }
  },

  methods: {

    /**
     * Select image
     * @param m
     */
    selectImage(m) {
      this.$emit('selected', m);
    },

    /**
     * Get ratio
     * @param m
     * @return {string}
     */
    getRatio(m) {
      switch (m.size) {
        case 'portrait':
          return 9/16;
        case 'landscape':
          return 16/9;
        default:
          return 1/1;
      }
    },

    /**
     * Get url
     * @param m
     * @return {boolean|*|string}
     */
    getUrl(m) {
      return this.renderer.a2u.assetPath(m?.media_file);
    }
  }
}

</script>
