<template>
  <div class="column media-instance-settings col" v-if="template?.id">

    <modal-dialog ref="selectImage" max-width max-height>
      <media-resource-selector :project-id="projectId" :solution-id="solutionId" :resource-type="resourceType" @selected="setCurrentImage"/>
    </modal-dialog>

    <q-scroll-area class="col">

      <div class="row">
        <div class="preview" :style="{width: previewWidth+'px', height: previewHeight + 'px'}">
          <media-instance-preview :media-instance="mediaInstance"/>
        </div>

        <div class="column col g-gutter-y-sm q-pl-md">
          <div class="" v-for="([name, val], k) of Object.entries(templateVars || [])" :key="k">

            <!--text!-->
            <q-input v-if="val.type === 'text'" class="col" v-model="mutate(mediaInstance).assets[name].value"
                     :label="`Value ${name} string`" :placeholder="val.text" :hint="val.text ? val.text : undefined"/>

            <!-- Image -->
            <!--image-loader input :upload="uploadUrl" v-if="val.type === 'image'" v-model="mutate(mediaInstance).assets[name].value" :label="`Image ${name}`" :image="getPreviewUrl(mediaInstance.assets[name].value)" width="50px" height="50px"/!-->
            <div v-if="['image', 'video'].includes(val.type)" class="col q-pa-sm bg-grey-4 q-mb-sm">
              <div class="q-pb-sm">{{ name }} {{ val.type }}</div>
              <div>
                <template v-if="val.type ==='image'">
                  <q-img width="50px" v-if="getPreviewUrl(mediaInstance.assets[name].value)"
                         :src="getPreviewUrl(mediaInstance.assets[name].value)" fit="contain"/>
                </template>
                <template v-else>
                  <q-icon name="play_circle" size="50px"/>
                </template>
                <q-btn flat icon="upload" @click="selectMedia(val.type, name)"/>
                <q-btn flat @click="mutate(mediaInstance).assets[name].value = ''" icon="delete"/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </q-scroll-area>

  </div>
</template>

<script>

import MediaInstancePreview from "@/components/MediaInstance/MediaInstancePreview.vue";
import {PromoTemplates} from "@/db/PromoTemplates";
import {renderMixins} from "app-to-up-vue-renderer/src/renderer/components/renderMixins";
import ModalDialog from "@/components/ModalDialog.vue";
import MediaResourceSelector from "@/components/MediaSelector/MediaResourceSelector.vue";
import {SolutionLandings} from "@/db/SolutionLandings";
import {ProjectSolutions} from "@/db/ProjectSolutions";
import {SolutionAds} from "@/db/SolutionAds";

export default {
  name: "MediaInstanceSettings",
  mixins: [renderMixins],
  inject: ['renderer', 'parentDiagram'],
  components: {MediaResourceSelector, ModalDialog, MediaInstancePreview},
  props: {
    mediaInstance: {}
  },

  data: () => ({
    template: false,
    //uploadUrl: ""
    projectId: false,
    solutionId: false,
    currentImageName: "",
    currentType: "",
    previewWidth: 300,
    previewHeight: 300,
    resourceType: "image",
  }),

  async created() {

    // Set upload url
    //this.uploadUrl = this.renderer.a2u.getCdnUrl()?.replace("/storage/download", "")

    // Get instance project
    [this.projectId, this.solutionId] = await this.getParentOwners();

    // Init assets
    if (!this.mediaInstance.assets) {
      // Init assets and start generating
      this.mutate(this.mediaInstance).assets = {}

      // Generate assets
      //await this.generateAssets()
    }

    // Get preview width and height according to subtype
    this.previewHeight = this.previewWidth / eval(this.parentDiagram.getStorage("constant").get("guides.mediaSizes").find(e => e.id === this.mediaInstance.subtype)?.ratio) || 1

    // Watch template vars
    this.$watch("templateVars", (vars) => {

      // Get current prompts
      const assets = this.mediaInstance.assets || {}

      // Init template prompts
      for (let vr in vars) if (!assets[vr] || typeof assets[vr] !== 'object') assets[vr] = {}

      // Store prompts
      this.mutate(this.mediaInstance).assets = assets;
    }, {deep: true, immediate: true});

    // Load template
    this.$watch("mediaInstance", this.loadTemplate, {
      immediate: true,
      deep: true
    });
  },

  methods: {

    /**
     * Generate assets
     * @return {Promise<void>}
     */
    async generateAssets() {

      try {
        //
        this.$q.loading.show()

        // Check if instance owner is "ad"
        if (this.mediaInstance.owner_type === 'ad') {
          // Generate and return assets
          const assets = await this.renderer.a2u.callFunction("AIComposer", "generatePromoAssets", {
            templateId: this.mediaInstance.promo_template_id,
            adId: this.mediaInstance.owner_id
          });

          // Set assets
          this.mutate(this.mediaInstance).assets = assets?.references?.promoAssets || {}
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.$q.loading.hide()
      }

    },

    /**
     * Set image
     */
    setCurrentImage(media) {
      // Set image
      this.mutate(this.mediaInstance).assets[this.currentImageName].value = media.media_file

      // Close dialog
      this.$refs.selectImage.hide()
    },

    /**
     * Select image
     * @param type
     * @param name
     */
    selectMedia(type, name) {

      // Set current type
      this.resourceType = type;

      // Current name to select image
      this.currentImageName = name

      // Show selector
      this.$refs.selectImage.show()
    },

    /**
     * Load project as media instance
     */
    async getParentOwners() {
      switch (this.mediaInstance.owner_type) {
        case 'landing':
          var landing = await SolutionLandings.find(this.mediaInstance.owner_id)
          var solution = await ProjectSolutions.find(landing.solution_id)
          return [solution.project_id, solution.id]
        case 'ad':
          var ad = await SolutionAds.find(this.mediaInstance.owner_id)
          var sol = await ProjectSolutions.find(ad.solution_id)
          return [sol.project_id, sol.id]
        default:
          return false
      }

    },

    /**
     * Get preview url
     * @param image
     * @return {string|undefined}
     */
    getPreviewUrl(image) {
      return image ? this.renderer.a2u.assetPath(image) : undefined
    },

    /**
     * Load template
     * @return {Promise<void>}
     */
    async loadTemplate() {
      // check if code exists
      if (!this.mediaInstance?.promo_template_id) return;

      // Load promo template
      this.template = await PromoTemplates.find(this.mediaInstance?.promo_template_id)

      // Exit if no template
      if (!this.template) {
        throw new Error('Template not found')
      }

      // Init placeholders
      for (const [key, value] of Object.entries(this.templateVars)) {
        if (!this.mediaInstance.assets[key] || typeof this.mediaInstance.assets[key] !== 'object')
          this.mutate(this.mediaInstance).assets[key] = value
      }

    }
  },

  computed: {

    /**
     * Get template vars
     * @return {{}}
     */
    templateVars() {

      // Texts to translate
      const textsToReplace = {};

      // Parse template source
      const templateSource = JSON.parse(this.template.template_code || "{}")

      // Go through all elements
      const getAllVars = (node) => {
        // Extract text assets from source
        for (const e of node?.elements || []) {
          if (e.dynamic) textsToReplace[e.name || e.id] = {
            text: e.text,
            type: e.type
          }
          getAllVars(e)
        }
      }

      // Process source
      getAllVars(templateSource);

      // Return texts
      return textsToReplace
    }
  },
}

</script>


<style lang="scss">

.media-instance-settings {
  .preview {
  }

}

</style>
