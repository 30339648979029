<template>
  <div class="video-to-asset column col q-gutter-y-sm">
    <q-select v-model="currentVideoId" map-options emit-value :options="videos" label="Select video"/>

    <div class="video-container col column full-width dg-items-center">
      <video
          ref="videoPlayer"
          :src="videoUrl"
          class="video-player"
          crossorigin="anonymous"
          @loadedmetadata="onVideoLoaded"
      ></video>
    </div>

    <div class="controls q-pl-md q-pr-md" v-if="videoUrl">
      <q-btn @click="togglePlay" class="control-btn" color="primary">
        {{ isPlaying ? 'Pause' : 'Play' }}
      </q-btn>

      <div class="frame-controls col row">
        <q-btn
            @click="prevFrame"
            class="frame-btn"
            color="primary"
            icon="chevron_left"
            dense
            flat
        />

        <input
            type="range"
            min="0"
            :max="duration"
            v-model="currentTime"
            @input="seekVideo"
            class="seek-bar col"
        />

        <q-btn
            @click="nextFrame"
            class="frame-btn"
            color="primary"
            icon="chevron_right"
            dense
            flat
        />
      </div>

      <q-btn
          @click="takeScreenshot"
          class="control-btn screenshot-btn"
          color="primary"
      >
        Take Screenshot
      </q-btn>
    </div>
  </div>
</template>

<script>
import {Projects} from "@/db/Projects";
import {ProjectMedia} from "@/db/ProjectMedia";
import {renderMixins} from "app-to-up-vue-renderer/src/renderer/components/renderMixins";

export default {
  name: "VideoToAsset",
  mixins: [renderMixins],
  emits: ['create-asset', 'error'],

  props: {
    projectId: {},
  },

  data: () => ({
    currentVideoId: null,
    isPlaying: false,
    duration: 0,
    currentTime: 0,
    framesToJump: 10,
    frameTime: 1/30, // Default frame time (30 fps)
  }),

  computed: {
    project() {
      return this.wait("project", Projects.find(this.projectId), {});
    },

    videoUrl() {
      const currentVideo = this.videos.find(v => v.value == this.currentVideoId)
      return currentVideo?.media_file?.source_url ? this.renderer.a2u.assetPath(currentVideo?.media_file) : false;
    },

    videos() {
      return this.wait("videos", async () => {
        const videos = await ProjectMedia.query().where({
          owner_id: this.projectId,
          owner_type: "project-video",
        }).get()

        return videos.map(v => ({
          label: v.description,
          media_file: v.media_file,
          value: v.id
        }))
      }, []);
    },
  },

  mounted() {
    ProjectMedia.remote().subscribe("Get owner media", {
      ownerId: this.projectId,
      ownerType: "project-video"
    });

    this.$refs.videoPlayer.addEventListener('timeupdate', this.setTime);
  },

  beforeUnmount() {
    this.$refs.videoPlayer.removeEventListener('timeupdate', this.setTime);
  },

  methods: {
    setTime() {
      this.currentTime = this.$refs.videoPlayer.currentTime;
    },

    nextFrame() {
      const video = this.$refs.videoPlayer;
      if (video) {
        if (!video.paused) {
          video.pause();
          this.isPlaying = false;
        }
        video.currentTime = Math.min(video.duration, video.currentTime + this.frameTime);
      }
    },

    prevFrame() {
      const video = this.$refs.videoPlayer;
      if (video) {
        if (!video.paused) {
          video.pause();
          this.isPlaying = false;
        }
        video.currentTime = Math.max(0, video.currentTime - this.frameTime);
      }
    },

    onVideoLoaded() {
      const video = this.$refs.videoPlayer;
      this.duration = video.duration;

      // Calculate frame time based on video properties
      let frameRate = 30; // Default fallback

      if (video.webkitDecodedFrameCount !== undefined) {
        const loadedFrames = video.webkitDecodedFrameCount;
        if (loadedFrames > 0) {
          frameRate = loadedFrames / video.duration;
        }
      } else if (video.mozDecodedFrames !== undefined) {
        const loadedFrames = video.mozDecodedFrames;
        if (loadedFrames > 0) {
          frameRate = loadedFrames / video.duration;
        }
      }

      this.frameTime = this.framesToJump / frameRate;
    },

    togglePlay() {
      if (this.isPlaying) {
        this.$refs.videoPlayer.pause();
      } else {
        this.$refs.videoPlayer.play();
      }
      this.isPlaying = !this.isPlaying;
    },

    seekVideo() {
      this.$refs.videoPlayer.currentTime = this.currentTime;
    },

    async takeScreenshot() {
      const video = this.$refs.videoPlayer;

      // Pause the video when taking screenshot
      if (!video.paused) {
        video.pause();
        this.isPlaying = false;
      }

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set canvas dimensions to match video dimensions
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the full video frame
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      try {
        const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
        const file = new File([blob], 'screenshot.jpg', {type: 'image/jpeg'});
        const fn = `screenshot-${Math.random().toString(36).substring(7)}`;

        const res = await this.app.client.upload(fn, file, this.renderer.a2u.getBackendUrl());

        if (!res) throw new Error('Failed to upload screenshot');

        this.$emit('create-asset', {
          type: 'file',
          source_url: 'storage:' + res,
          storage_module_id: this.renderer.a2u.getBackendId(),
          title: fn,
        });

      } catch (error) {
        console.error('Error uploading screenshot:', error);
        this.$emit('error', error);
      }
    }
  }
}
</script>

<style lang="scss">
.video-to-asset {
  .video-container {
    position: relative;
    overflow: hidden;
  }

  .video-player {
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
